import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings"
import React from "react"
import { Button, Card, Col, ListGroupItem, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"

type StatisticCardProps = {
  show: boolean
  title: string
  loading: boolean
  isDetailed?: boolean
  isDeviceActive?: boolean
  totalCount?: any
  titles?: string[]
  detailCounts?: any[]
  onButtonClick?: () => void
}

const pageClassName = "shadow-2 rounded mt-2 mb-2"
export const alignCenterBetween = "d-flex flex-row align-items-center justify-content-between"

export const StatisticCard: React.FC<StatisticCardProps> = ({
  show,
  title,
  loading,
  children,
  isDetailed,
  isDeviceActive,
  totalCount,
  titles,
  detailCounts,
  onButtonClick,
}) => {
  const { brand } = usePartner()
  const { t } = useTranslation()
  const styles = {
    borderStyles: {
      borderWidth: 1.5,
      borderColor: brand.color,
      borderRadius: "25px",
      minHeight: 160,
    },
  }
  if (show) {
    return (
      <Col>
        <Card className={pageClassName} style={styles.borderStyles}>
          <Card.Body>
            {!isDetailed ? (
              <>
                <div className="d-flex flex-row">
                  {children}
                  <Card.Title className="ms-2">{title}</Card.Title>
                </div>
                {loading ? (
                  <Spinner style={sortArrowStyles.loader} animation="border" />
                ) : (
                  <>
                    {isDeviceActive && totalCount == 0 ? (
                      <div className="d-flex flex-column">
                        <label>{t("settings.partner.no_trackers")}</label>
                        <Button className="mt-2 align-self-end" onClick={onButtonClick}>
                          {t("settings.partner.add_tracker")}
                        </Button>
                      </div>
                    ) : (
                      <Card.Text className="fs-1">{totalCount}</Card.Text>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className={alignCenterBetween}>
                  <div className="d-flex flex-row mt-1">
                    {children}
                    <Card.Title className="ms-1">{title}</Card.Title>
                  </div>
                  {totalCount && (
                    <div className="align-self-start me-3">
                      <b className="fs-4">{totalCount}</b>
                    </div>
                  )}
                </div>
                {loading ? (
                  <Spinner style={sortArrowStyles.loader} animation="border" />
                ) : (
                  <div>
                    <ListGroupItem className={alignCenterBetween}>
                      <label className="small">{titles[0]}</label>
                      <b className="small">{detailCounts[0]}</b>
                    </ListGroupItem>
                    {detailCounts.length == 2 && (
                      <ListGroupItem className={alignCenterBetween}>
                        <label className="small">{titles[1]}</label>
                        <b className="small">{detailCounts[1]}</b>
                      </ListGroupItem>
                    )}
                  </div>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    )
  }
}
