import { callApi } from "../callApi"
import { ROLES_TITLE } from "./getPartnerUuidRoles"

type ROLESRULES = {
  base_behavior: boolean
  actions: string[]
  subjects: string[]
  conditions: any
}

export type LOGGED_USER_ROLE = {
  name: ROLES_TITLE
  rules: ROLESRULES
}

export const getPartnerUuidRolesMe = async (partnerUuid: string) => {
  return callApi<null, LOGGED_USER_ROLE>({
    method: "GET",
    path: `/partner/${partnerUuid}/roles/me`,
  })
}
