import base64 from "base-64"
import FormData from "form-data"
import { mailgun } from "@src/config"

export type emailProps = {
  from: string
  subject: string
  text: string
}

type MAILGUN_RESPONSE = {
  message: string
  id: string
}

export const sendEmailViaMailgun = async ({ from, subject, text }: emailProps): Promise<MAILGUN_RESPONSE> => {
  const headerReq = {
    Authorization: `Basic ${base64.encode(`api:${mailgun.apiKey}`)}`,
    Accept: "application/json",
  }

  const formData = new FormData()
  formData.append("from", from)
  formData.append("to", mailgun.support_email) 
  formData.append("bcc", "")
  formData.append("subject", subject)
  formData.append("text", text)

  return fetch(mailgun.mailgunEndPoint, {
    method: "POST",
    headers: headerReq,
    // TypeScript Error:: FormData type is not included in RequestBody type
    // But MailGun is not supporting JSON object or string type as body
    body: formData,
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}
