import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  deleteFleetsUuidFencesIdRemoveDevices,
  deleteFleetsUuidRemoveDevices,
  getDevice,
  getFleetsUuidDevices,
  postFleetsUuidAddDevices,
  putFleetsUuidFencesIdAddDevices,
} from "@src/api"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { Form, ListGroup, Spinner } from "react-bootstrap"
import { usePartner } from "@src/partner"
import { colors, config } from "@src/constants"
import { ModalView } from "@src/components/ModalView"
import { useParams } from "react-router-dom"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { useFleets } from "../fleets"
import { useFence } from "../geofence"
import _ from "lodash"
import { colorTagStyle } from "@src/device"

export enum SearchDeviceType {
  Fleet,
  Fence,
}

type DevicesSelectTabProps = {
  type: SearchDeviceType
}

const styles = {
  divBox: {
    display: "flex",
    flexDirection: "column" as const,
    height: "350px",
  },
  listGroup: {
    overflow: "scroll",
    flex: 1,
    display: "flex",
  },
}

export const DevicesSelectTab: React.FC<DevicesSelectTabProps> = ({ type }) => {
  const { t } = useTranslation()
  const { partner } = usePartner()
  const { fleet } = useFleets()
  const { fence, loadFenceDevices, fenceDevices } = useFence()
  const [loading, setLoading] = useState<boolean>(false)
  const [results, setResults] = useState<SLIM_DEVICE_TYPE[]>([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<SLIM_DEVICE_TYPE[]>()
  const [selectedDevice, setSelectedDevice] = useState<SLIM_DEVICE_TYPE>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const { fleetUuid } = useParams()
  const [searchText, setSearchText] = useState<string>("")

  useEffect(() => {
    setLoading(true)
    if (partner) {
      getDevicesList()
    }
  }, [searchText, partner])

  useEffect(() => {
    if (selectedCheckboxes && searchText == "") sortArray(results)
  }, [selectedCheckboxes])

  const getDevicesList = () => {
    getDevice(partner?.uuid, null, {
      page: 0,
      per_page: config.itemsPerPage,
      search_string: searchText,
    }).then(({ response, data }) => {
      if (response.ok) {
        setResults(data.devices)
        if (searchText != "") setLoading(false)
        type == SearchDeviceType.Fleet ? loadFleetDevices() : loadFenceDevices()
      }
    })
  }

  useEffect(() => {
    if (fenceDevices && type == SearchDeviceType.Fence && results.length != 0) {
      setDevices(fenceDevices)
      setSelectedCheckboxes(fenceDevices)
    }
  }, [fenceDevices])

  const loadFleetDevices = () => {
    if (partner && fleet) {
      getFleetsUuidDevices(partner.uuid, fleetUuid, {
        page: 0,
        per_page: config.itemsPerPage,
      }).then(({ response, data }) => {
        if (response.ok) {
          setDevices(data.devices)
          setSelectedCheckboxes(data.devices)
        }
      })
    }
  }

  const handleCheckbox = (result) => {
    setSelectedDevice(result)
    if (result.fleet && result.fleet.uuid != fleet.uuid) {
      setShowModal(true)
    } else {
      const checkedBoxes = [...selectedCheckboxes]
      if (checkedBoxes.some((ch) => ch.serialnumber === result.serialnumber)) {
        const index = checkedBoxes.findIndex((ch) => ch.serialnumber === result.serialnumber)
        removeDevice(checkedBoxes[index], false)
        checkedBoxes.splice(index, 1)
      } else {
        checkedBoxes.push(result)
        addDevice(result)
      }
    }
  }

  const addDevice = (device: SLIM_DEVICE_TYPE) => {
    if (type == SearchDeviceType.Fleet) {
      postFleetsUuidAddDevices(partner.uuid, fleetUuid, { device_serialnumbers: [device.serialnumber] }).then(
        ({ response, data }) => {
          if (response.ok) {
            setSearchText("")
            loadFleetDevices()
          }
        },
      )
    } else {
      putFleetsUuidFencesIdAddDevices(partner.uuid, fleet.uuid, fence.id, {
        device_serialnumbers: [device.serialnumber],
      }).then(({ response, data }) => {
        setSearchText("")
        loadFenceDevices()
      })
    }
  }

  const removeDevice = (device: SLIM_DEVICE_TYPE, isRemoveAdd: boolean) => {
    if (type == SearchDeviceType.Fleet) {
      deleteFleetsUuidRemoveDevices(partner.uuid, isRemoveAdd ? device.fleet.uuid : fleetUuid, {
        device_serialnumbers: [device.serialnumber],
      }).then(({ response, data }) => {
        if (response.ok) {
          if (isRemoveAdd) addDevice(device)
          setSearchText("")
          loadFleetDevices()
        }
      })
    } else {
      deleteFleetsUuidFencesIdRemoveDevices(partner.uuid, fleet.uuid, fence.id, {
        device_serialnumbers: [device.serialnumber],
      }).then(({ response, data }) => {
        setSearchText("")
        loadFenceDevices()
      })
    }
  }

  const sortArray = (deviceArray: SLIM_DEVICE_TYPE[]) => {
    devices.map((device) => {
      let findIndex = deviceArray.findIndex((item) => item.serialnumber == device.serialnumber)
      deviceArray.splice(findIndex, 1)
    })
    if (searchText == "") setResults([...selectedCheckboxes, ...deviceArray])
    setSelectedDevice(null)
    setLoading(false)
  }

  return (
    <div>
      <Form.Control
        type="search"
        id="search"
        autoComplete="off"
        placeholder={t("sidebar.search_placeholder")}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value.toString())
          _.debounce(getDevicesList, 2000)
        }}
      />
      <div style={styles.divBox}>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <Spinner size="sm" animation="border" />
          </div>
        ) : (
          <ListGroup className="mt-2" style={styles.listGroup}>
            {results.map((result) => (
              <ListGroup.Item
                key={result.serialnumber}
                onClick={() => {
                  handleCheckbox(result)
                }}>
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    {selectedDevice && selectedDevice.serialnumber == result.serialnumber ? (
                      <div style={sortArrowStyles.loader} />
                    ) : (
                      <input
                        type="checkbox"
                        id={`device${result.serialnumber}`}
                        name="deviceId"
                        checked={
                          selectedCheckboxes && selectedCheckboxes.some((ch) => ch.serialnumber === result.serialnumber)
                        }
                        onChange={() => {}}
                      />
                    )}
                  </div>
                  <label className="ms-2">
                    {result.serialnumber} - {result.name}
                  </label>
                  {type == SearchDeviceType.Fleet && fleet && result.fleet && result.fleet.uuid !== fleet.uuid && (
                    <b className="ms-2" style={colorTagStyle(colors.red)}>
                      {" "}
                      ({result.fleet.name})
                    </b>
                  )}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
        {selectedDevice && fleet && type == SearchDeviceType.Fleet && (
          <ModalView
            title={t("settings.fleets.alert")}
            buttonTitle={t("settings.fleets.add")}
            show={showModal}
            buttonLoader={false}
            onHideModal={() => {
              setSelectedDevice(null)
              setShowModal(false)
            }}
            onButtonClick={() => {
              setShowModal(false)
              selectedCheckboxes.push(selectedDevice)
              removeDevice(selectedDevice, true)
            }}>
            <label>
              {t("settings.fleets.adding")} {selectedDevice.name} {t("settings.fleets.in")} <b>{fleet.name}</b>{" "}
              {t("settings.fleets.willremovefrom")}{" "}
              <b style={colorTagStyle(colors.red)}>{selectedDevice.fleet && selectedDevice.fleet.name}</b>
            </label>
          </ModalView>
        )}
      </div>
    </div>
  )
}
