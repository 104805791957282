import React, { createContext, useContext, useEffect, useState } from "react"
import { useAuthentication } from "@src/auth"
import { getUsersMe, putUsers } from "@src/api"
import { useLocalization } from "@src/localization"
import { mToKm, yardToMiles } from "@src/utils"

export enum SYSTEM_OF_MEASUREMENT {
  IMPERIAL = "imperial",
  METRIC = "metric",
}

export type USER = {
  firstname: string
  lastname: string
  address: string
  langcode: string
  system_of_measurement: SYSTEM_OF_MEASUREMENT
}

type USER_CONTEXT = {
  user: USER
  updateUser: (user: USER) => void
  distanceConversion: (value: number) => string
}

const DEFAULT_USER_CONTEXT = {
  user: null,
  updateUser: () => {},
  distanceConversion: () => "",
}

export const UserContext = createContext<USER_CONTEXT>(DEFAULT_USER_CONTEXT)

export const UserProvider: React.FC = ({ children }) => {
  const { changeLanguage } = useLocalization()
  const { isAuthenticated } = useAuthentication()
  const [user, setUser] = useState<USER>()

  useEffect(() => {
    if (isAuthenticated)
      setTimeout(() => {
        getUser()
      }, 0)
    else {
      setUser(null)
    }
  }, [isAuthenticated])

  const getUser = (): Promise<USER> => {
    return getUsersMe().then(({ response, data }) => {
      if (response.ok) {
        setUser(data)
        changeLanguage(data.langcode)
        return data
      }
    })
  }

  const updateUser = (userData: USER) => {
    putUsers({
      user: userData,
    }).then(({ response, data }) => {
      if (response.ok) {
        setUser(data)
        changeLanguage(data.langcode)
      }
    })
  }

  const distanceConversion = (value: number) => {
    if (user?.system_of_measurement === SYSTEM_OF_MEASUREMENT.METRIC) {
      return mToKm(value)
    } else {
      return yardToMiles(value)
    }
  }

  const context = {
    user,
    updateUser,
    distanceConversion,
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
