import React, { useEffect, useState } from "react"
import { putDeviceSerialnumberUnlock, putDeviceSerialnumberLock } from "@src/api/requests"
import { Spinner, Button } from "react-bootstrap"
import { LockUnlockIcon } from "@src/components"
import { usePartner } from "@src/partner"
import { DEVICE_LOADING_STATE, useDevice } from "@src/device/useDevice"
import { iLockitDevices } from "@src/constants"
import { postiLockitLockStatus } from "@src/api/ilockit/postiLockitLockStatus"

type LockUnlockButtonProps = {
  serialnumber: string
}

export const LockUnlockButton: React.FC<LockUnlockButtonProps> = ({ serialnumber }) => {
  const { device, loadDevice } = useDevice()
  const { partner } = usePartner()
  let locked = device?.locked == "locked"
  const [loading, setLoading] = useState<boolean>(false)
  const isiLockit = iLockitDevices.includes(serialnumber)

  useEffect(() => {
    setLoading(false)
  }, [device])

  const onLockUnlock = async () => {
    setLoading(true)

    if (isiLockit) {
      const response = await postiLockitLockStatus({ partnerUuid: partner?.uuid, serialnumber }, { open: locked })
      if (!response?.response?.ok) return setLoading(false)
    }

    if (locked) {
      await putDeviceSerialnumberUnlock(partner?.uuid, serialnumber)
    } else {
      await putDeviceSerialnumberLock(partner?.uuid, serialnumber)
    }
    loadDevice(serialnumber, null)
  }

  return (
    <div>
      <Button variant={"light"} onClick={onLockUnlock} disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : <LockUnlockIcon locked={device?.locked} />}
      </Button>
    </div>
  )
}
