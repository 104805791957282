import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { AuthenticationProvider } from "./auth"
import { PartnerProvider } from "./partner"
import { LocalizationProvider } from "./localization/useLocalization"
import reportWebVitals from "./reportWebVitals"
import "./index.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { UserProvider } from "./user"
import { FleetProvider } from "./settings"
import { DeviceProvider } from "./device/useDevice"
import { FenceProvider } from "./settings"

const container: any | null = document.getElementById("root")

const root = ReactDOM.createRoot(container)
root.render(
  <BrowserRouter>
    <AuthenticationProvider>
      <PartnerProvider>
        <DeviceProvider>
          <LocalizationProvider>
            <UserProvider>
              <FleetProvider>
                <FenceProvider>
                  <App />
                </FenceProvider>
              </FleetProvider>
            </UserProvider>
          </LocalizationProvider>
        </DeviceProvider>
      </PartnerProvider>
    </AuthenticationProvider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
