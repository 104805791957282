import React, { useEffect, useState } from "react"
import { DEVICE_TYPE } from "@src/devices/types"
import { Button, Col, ListGroup, Row } from "react-bootstrap"
import { Map, polygonColors } from "./components"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { useLocalization } from "@src/localization"
import { colors } from "@src/constants"
import { InputField } from "@src/components"
import { mailgun } from "@src/config"
import { useDevice } from "@src/device/useDevice"
import { useNavigate } from "react-router-dom"
import * as Icon from "react-bootstrap-icons"
import { sToH } from "@src/utils"
import { useUser } from "@src/user"
import { TRIP } from "@src/api/types"

type DetailsTabProps = {
  serialnumber: string
}

const styles = {
  verticalDivider: { backgroundColor: colors.gray, flex: 1, height: 0.8 },
  warningText: {
    backgroundColor: colors.red,
    borderRadius: 5,
    color: colors.white,
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 5,
    paddingLeft: 15,
  },
  datapointsText: { borderRadius: 5, color: colors.red, padding: 2 },
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ serialnumber }) => {
  const { t } = useTranslation()
  const { formatDateTime } = useLocalization()
  const { permissions } = usePartner()
  const [loading, setLoading] = useState<string>("")
  const { patchDevice, device, loadLastTrips, trips } = useDevice()
  const [deviceData, setDeviceData] = useState<DEVICE_TYPE>(device)
  const serviceEndDate = deviceData?.servicebooking?.service_end_date
    ? formatDateTime(new Date(deviceData.servicebooking.service_end_date))
    : "-"
  const lastPositionTimestamp = device.last_position_timestamp
    ? formatDateTime(new Date(deviceData.last_position_timestamp))
    : t("device_screen.details_tab.no_gps_found")
  const lastSeenTimestamp = deviceData?.last_seen_timestamp
    ? formatDateTime(new Date(deviceData.last_seen_timestamp))
    : "-"
  const googleMapsLink =
    deviceData?.position && !deviceData?.position.error
      ? `https://www.google.com/maps/search/?api=1&query=${deviceData.position.lat},${deviceData.position.lng}`
      : ""
  const [totalDistance, setTotalDistance] = useState<Number>(0)
  const [totalDuration, setTotalDuration] = useState<Number>(0)
  const { user, distanceConversion } = useUser()

  useEffect(() => {
    setDeviceData(device)
    loadLastTrips(serialnumber)
  }, [device])

  useEffect(() => {
    calculateTotalDistanceAndDuration()
  }, [trips])

  const onInputPersist = (field: string) => async (text: string) => {
    setLoading(field)
    const newDevice = { ...deviceData, [field]: text }
    let statusOk = await patchDevice(newDevice)
    statusOk && setLoading("")
  }

  const chooseRandomPolygonColors = (index: number) => {
    return { backgroundColor: polygonColors[index] }
  }

  const calculateTotalDistanceAndDuration = () => {
    let totalKm = 0
    let totalTime = 0
    trips.map((trip) => {
      totalKm += trip.distance
      totalTime += trip.duration_s
    })
    setTotalDistance(totalKm)
    setTotalDuration(totalTime)
  }

  return (
    <Row className="d-flex flex-column flex-lg-row bg-highlight">
      <Col>
        {deviceData?.servicebooking ? (
          <div>
            <Map
              position={!deviceData?.position?.error && deviceData?.position}
              serialnumber={serialnumber}
              showRoute={false}
              fences={deviceData?.fences}
              fenceEditable={false}
            />
            <Row className="mt-1  flex-lg-column">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                  <b className="small"> {t("device_screen.details_tab.last_seen")}:</b>
                  <label className="mt-1">{lastSeenTimestamp}</label>
                  <b className="small mt-2"> {t("dashboard_screen.total_distance")}:</b>
                  <label className="mt-1">{distanceConversion(totalDistance as number)}</label>
                </div>
                <div className="d-flex flex-column">
                  <b className="small"> {t("device_screen.details_tab.last_position")}:</b>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <label className="mt-1">{lastPositionTimestamp}</label>
                    <a className="ms-2 mb-1" href={`${googleMapsLink}`} target="_blank">
                      <Icon.BoxArrowUpRight></Icon.BoxArrowUpRight>
                    </a>
                  </div>
                  <b className="small mt-2"> {t("device_screen.route_tab.total_duration")}:</b>
                  <label className="mt-1">{sToH(totalDuration)} h</label>
                </div>
                <div className="d-flex flex-column">
                  <b className="small"> {t("devices_screen.service_booking")}:</b>
                  <label className="mt-1">{serviceEndDate}</label>
                </div>
              </div>
            </Row>
            {deviceData?.fences.length != 0 && (
              <div className="d-flex flex-column mt-2 mb-2">
                <b className="small">{t("device_screen.details_tab.fence")}:</b>
                <div>
                  {deviceData?.fences?.map((item, i) => {
                    return (
                      <Button
                        className="m-1 btn btn-primary-outline d-inline-flex justify-content-center align-items-center"
                        disabled
                        onClick={() => {
                          //TODO: this will be enabled once this PR is merged https://bitbucket.org/iotventure/iot-dashboard/pull-requests/112
                          //navigate("")
                        }}>
                        {item.name} <div className="colored-circle" style={chooseRandomPolygonColors(i)} />
                      </Button>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div>
              <label className="ms-1 mt-2" style={styles.datapointsText}>
                <b>{t("device_screen.details_tab.datapoints_warn")}</b>
              </label>
            </div>
            <div className="d-flex flex-column mb-1">
              <b className="small"> {t("devices_screen.service_booking")}</b>
              <div>
                <label className="ms-1 mt-2" style={styles.warningText}>
                  {t("device_screen.details_tab.no_service").toUpperCase()}
                </label>
                <label className="ms-1 mt-2">
                  {t("device_screen.details_tab.no_service_msg")}
                  <a
                    href={`mailto:IoT-Venture Support<${mailgun.support_email}>?subject=${t(
                      "device_screen.details_tab.support_subject",
                    )}&body=${t("device_screen.details_tab.support_content")} ${deviceData?.serialnumber}`}>
                    {t("device_screen.details_tab.support")}
                  </a>
                </label>
              </div>
            </div>
          </>
        )}
      </Col>
      <Col>
        {/* <InputField
          label={t("device_screen.details_tab.commissioned")}
          value={deviceData?.commissioning && formatDateTime(new Date(deviceData.commissioning))}
          onPersist={onInputPersist("commissioned")}
          loading={loading == "commissioned"}
          isReadOnly={true}
        />

        <div className="mt-2 mb-3" style={styles.verticalDivider} /> */}
        {/* <InputField
          label={t("device_screen.details_tab.description")}
          value={deviceData?.description}
          onPersist={onInputPersist("description")}
          loading={loading == "description"}
          isReadOnly={!permissions.editDevices}
        /> */}
        <InputField
          label={t("device_screen.details_tab.framenumber")}
          value={deviceData?.framenumber}
          onPersist={onInputPersist("framenumber")}
          loading={loading == "framenumber"}
          isReadOnly={!permissions.editDevices}
        />
        <InputField
          label={t("device_screen.details_tab.framecolor")}
          value={deviceData?.framecolor}
          onPersist={onInputPersist("framecolor")}
          loading={loading == "framecolor"}
          isReadOnly={!permissions.editDevices}
        />
        <InputField
          label={t("device_screen.details_tab.deviceoptions")}
          value={deviceData?.bikeoptions}
          onPersist={onInputPersist("bikeoptions")}
          loading={loading == "bikeoptions"}
          isReadOnly={!permissions.editDevices}
        />
        <InputField
          label={t("device_screen.details_tab.brand")}
          value={deviceData?.brand}
          onPersist={onInputPersist("brand")}
          loading={loading == "brand"}
          isReadOnly={!permissions.editDevices}
        />
        <InputField
          label={t("device_screen.details_tab.devicemodel")}
          value={deviceData?.bikemodel}
          onPersist={onInputPersist("bikemodel")}
          loading={loading == "bikemodel"}
          isReadOnly={!permissions.editDevices}
        />
        <InputField
          label={t("device_screen.details_tab.owner_name")}
          value={deviceData?.owner_name}
          onPersist={onInputPersist("owner_name")}
          loading={loading == "owner_name"}
          isReadOnly={!permissions.editDevices}
        />
        <InputField
          label={t("device_screen.details_tab.owner_address")}
          value={deviceData?.owner_address}
          onPersist={onInputPersist("owner_address")}
          loading={loading == "owner_address"}
          isReadOnly={!permissions.editDevices}
        />
        <InputField
          label={t("device_screen.details_tab.owner_phone")}
          value={deviceData?.owner_phone}
          onPersist={onInputPersist("owner_phone")}
          loading={loading == "owner_phone"}
          isReadOnly={!permissions.editDevices}
        />
      </Col>
    </Row>
  )
}
