import { colors } from "@src/constants"
import { colorTagStyle } from "@src/device"
import { useLocalization } from "@src/localization"
import { StyleSheet } from "@src/types"
import React, { useState, useEffect } from "react"
import { Form, Spinner } from "react-bootstrap"

type InputFieldProps = {
  name?: string
  value: string
  onPersist: (text: string) => void
  loading: boolean
  label?: string
  maxLength?: number
  isReadOnly?: boolean
  onChangeText?: (text: string) => void
  validate?: boolean
  errorMsg?: string
  isMandatory?: boolean
  isFocused?: boolean
  onKeyTab?: (e: any) => void
}

const styles: StyleSheet = {
  fieldPadding: {
    padding: ".375rem .75rem",
    margin: "1px",
    cursor: "text",
    flex: 1,
  },
  onHover: {
    backgroundColor: colors.middleGray,
    borderRadius: 4,
  },
}

export const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  value,
  onPersist,
  loading,
  maxLength,
  isReadOnly,
  onChangeText,
  validate,
  errorMsg,
  isMandatory,
  isFocused,
  onKeyTab,
}) => {
  const [focus, setFocus] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)
  const [internalValue, setInternalValue] = useState<string>(value)
  const [error, setError] = useState<string>()
  const { language } = useLocalization()

  useEffect(() => {
    setError("")
    setInternalValue(value)
  }, [value])

  useEffect(() => {
    setError("")
  }, [language])

  useEffect(() => {
    if (isFocused) setFocus(isFocused)
  }, [isFocused])

  const validateFields = () => {
    if (validate) {
      setError(errorMsg)
    } else {
      setError("")
      onPersist(internalValue)
    }
  }

  return (
    <>
      {label && (
        <b className="small">
          {label}
          {isMandatory && <b style={colorTagStyle(colors.red)}>*</b>}:
        </b>
      )}
      {!focus ? (
        <div className="d-flex align-items-center text-truncate">
          <Form.Label
            name={name}
            onClick={() => {
              if (!isReadOnly) {
                setError("")
                setFocus(true)
              }
            }}
            style={{
              ...styles.fieldPadding,
              ...(hover && styles.onHover),
            }}
            className="d-inline text-truncate"
            onMouseEnter={() => setHover(!isReadOnly)}
            onMouseOut={() => setHover(false)}>
            {internalValue || "-"}
          </Form.Label>
          {loading && <Spinner animation="border" size="sm" />}
        </div>
      ) : (
        <Form.Control
          name={name}
          required
          type={"text"}
          autoFocus
          value={internalValue}
          maxLength={maxLength}
          onChange={(e) => {
            setInternalValue(e.target.value)
            onChangeText && onChangeText(e.target.value)
          }}
          readOnly={isReadOnly}
          onBlur={(e) => {
            setFocus(false)
            setHover(false)
            isMandatory && validateFields()
            internalValue != value && validateFields()
          }}
          onKeyDown={(e) => {
            if (e.key === "Tab") {
              const [fieldName, fieldIndex] = name.split("-")
              const nextlabel = document.querySelector(`label[name=field-${parseInt(fieldIndex) + 1}]`) as HTMLElement
              const currentField = document.querySelector(`input[name=field-${parseInt(fieldIndex)}]`) as HTMLElement
              if (nextlabel) {
                currentField.blur()
                setTimeout(() => {
                  nextlabel.click()
                }, 100)
              }
            }
          }}
        />
      )}
      <label className="small d-flex mb-2" style={{ color: colors.red }}>
        {error}
      </label>
    </>
  )
}
