import { useEffect } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { usePartner } from "@src/partner"
import { SideMenu } from "./components"
import { useFleets } from "@src/settings"
import { FleetDropDown } from "../FleetDropDown"
import { colors } from "@src/constants"
import { StyleSheet } from "@src/types"
import { useTranslation } from "react-i18next"
import { PermissionCard } from "../PermissionCard"

type PageProps = {
  fullscreen?: boolean
  isSettings?: boolean
  showFleet?: boolean
  showFleetWithoutAll?: boolean
  showHeader?: boolean
  headerValue?: string
  isRelative?: boolean
  onSelectFleet?: () => void
}

const styles: StyleSheet = {
  headerText: {
    fontSize: 22,
  },
  divider: {
    backgroundColor: colors.gray,
    height: 1,
    width: "100%",
  },
  relative: { position: "relative" },
  warningCard: { backgroundColor: colors.whiteGray },
}

export const Page: React.FC<PageProps> = ({
  fullscreen,
  children,
  isSettings,
  showFleet,
  showFleetWithoutAll,
  showHeader,
  headerValue,
  isRelative,
  onSelectFleet,
}) => {
  const { t } = useTranslation()
  const shadow = "shadow bg-white rounded "
  const { permissions, partner, changePartner } = usePartner()
  const { partnerUuid, fleetUuid } = useParams()
  const { updateFleetUuid } = useFleets()

  useEffect(() => {
    if (fleetUuid) updateFleetUuid(fleetUuid)
    if (partnerUuid && partner?.uuid != partnerUuid) {
      changePartner(partnerUuid)
    }
  }, [partnerUuid, fleetUuid, partner])

  return (
    <Container>
      {permissions && !permissions.viewDevices && !fullscreen ? (
        <PermissionCard />
      ) : (
        <Row>
          {!fullscreen && (
            <Col xs={12} md={4} lg={3}>
              <SideMenu isSettings={isSettings} />
            </Col>
          )}
          <Col
            xs={12}
            md={fullscreen ? 12 : 8}
            lg={fullscreen ? 12 : 9}
            style={styles.relative}
            className={`${!fullscreen ? shadow + "mt-4" : "mt-4"} mb-4 p-4`}>
            <div className="m-1">
              {showHeader && (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="large" style={styles.headerText}>
                      {headerValue}
                    </label>
                    {showFleet && (
                      <FleetDropDown withoutAllOption={showFleetWithoutAll} onClick={(uuid) => onSelectFleet()} />
                    )}
                  </div>
                  <div className="mt-2 mb-2" style={styles.divider}></div>
                </>
              )}
              {children}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  )
}
