import { callApi } from "@src/api/callApi"

type POST_FLEET_ENABLE_POWER_BODY = {
  interval: number
}

export const postFleetsUuidBatchEnablePowersave = (
  partnerUuid: string,
  fleetUuid: string,
  body: POST_FLEET_ENABLE_POWER_BODY,
) =>
  callApi<POST_FLEET_ENABLE_POWER_BODY, any>({
    method: "POST",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/batch_enable_powersaving`,
    body,
  })
