import React, { useEffect, useState } from "react"
import { FleetDropDown, InputField, ModalView } from "@src/components"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { defaultCenter, Map } from "@src/device/devicedetail/tabs/components"
import { Button, Spinner, Col, Form } from "react-bootstrap"
import { getDevice, postFleetsUuidFences, putFleetsUuidFencesIdAddDevices } from "@src/api"
import { usePartner } from "@src/partner"
import { useNavigate, useParams } from "react-router-dom"
import { useFleets } from "@src/settings"
import { useFence } from "../useFence"
import { SearchCityFence } from "./components"
import { fencingArrtoCoordinates, fencingPostStrToArr } from "@src/utils/coordinates"

export const FenceDetailTab: React.FC = () => {
  const { t } = useTranslation()
  const { partner } = usePartner()
  const { fleet, updateFleetUuid } = useFleets()
  const { fence, loadFenceById, patchFence, loadFences } = useFence()
  const [fencingpost, setFencingpost] = useState([])
  const [fenceName, setFenceName] = useState<string>("")
  const { fenceUuid } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [successMsg, setSuccessMsg] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>("")
  const [initialLoad, setInitialLoad] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (fenceUuid) {
      setInitialLoad(true)
      loadFenceById(parseInt(fenceUuid))
    }
  }, [fenceUuid])

  useEffect(() => {
    if (fence) {
      setInitialLoad(false)
      setFenceName(fence.name)
      const convertedPosts =
        typeof fence.fencingposts == "string"
          ? fencingPostStrToArr(fence.fencingposts)
          : fencingArrtoCoordinates(fence.fencingposts)
      setFencingpost(convertedPosts)
    }
  }, [fence])

  const createFence = async () => {
    if (fenceName == "") {
      setAlertMsg(t("settings.fence.validate.name"))
    } else if (!fleet) {
      setAlertMsg(t("settings.fence.validate.fleet_select"))
    } else if (fencingpost.length < 3) {
      setAlertMsg(t("settings.fence.validate.fencing_post"))
    } else {
      setLoading(true)
      const stringPaths = fencingpost.map((latLng) => {
        return `[${latLng.lat}, ${latLng.lng}]`
      })
      let body = { fence: { kind: "classic", fencingposts: `[${stringPaths}]`, name: fenceName } }
      if (fenceUuid) {
        let response = await patchFence(body)
        if (response.ok) {
          setSuccessMsg(true)
          setLoading(false)
        }
      } else {
        postFleetsUuidFences(partner.uuid, fleet.uuid, body).then(({ response, data }) => {
          setSuccessMsg(true)
          loadFences()
        })
      }
    }
    setShowModal(true)
  }

  const onInputPersist = (field: string) => (text: string) => {
    setFenceName(text)
  }

  const spinLoader = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <div>
      {initialLoad ? (
        spinLoader()
      ) : (
        <div className="d-flex flex-column-reverse flex-lg-row bg-highlight">
          <Col className="me-4">
            <Map
              position={fencingpost.length != 0 ? fencingpost[0] : defaultCenter}
              isFence
              paths={fencingpost}
              fenceEditable
              serialnumber={""}
              showRoute={false}
              onClickMap={(fencePath) => {
                setFencingpost(fencePath)
              }}
            />
            <Form.Text>{t("settings.fence.double_click")}</Form.Text>
          </Col>
          <Col xs={12} md={6} lg={4}>
            {!fenceUuid && (
              <>
                <InputField
                  label={t("settings.fence.geofence_name")}
                  value={fenceName}
                  onPersist={onInputPersist("name")}
                  loading={false}
                  onChangeText={() => onInputPersist("name")}
                />

                <b>{t("settings.fleets.select_fleet")}</b>
                <div className="d-flex mt-2 mb-3">
                  <FleetDropDown
                    withoutAllOption
                    fleetUpdate={false}
                    onClick={(fleet) => {
                      updateFleetUuid(fleet.uuid)
                    }}
                  />
                </div>
              </>
            )}

            <Form.Text>{t("settings.fence.click_fence")}</Form.Text>

            <SearchCityFence onSearchResult={(coords) => setFencingpost(coords)} />

            <div className="d-flex">
              {loading ? (
                spinLoader()
              ) : (
                <Button
                  className="mt-3 btn-primary-outline"
                  onClick={() => {
                    setAlertMsg("")
                    createFence()
                  }}>
                  {fenceUuid ? t("settings.fence.update") : t("settings.fence.create")}
                </Button>
              )}
            </div>
          </Col>
        </div>
      )}
      <ModalView
        title={t("settings.fleets.alert")}
        buttonTitle={t("device_screen.ok")}
        show={showModal}
        buttonLoader={false}
        onHideModal={() => setShowModal(false)}
        onButtonClick={() => {
          if (successMsg) {
            setSuccessMsg(false)
            navigate(-1)
          }
          setShowModal(false)
        }}>
        {!successMsg ? (
          <label>{alertMsg}</label>
        ) : (
          <div className="alert alert-success mt-2" role="alert">
            {fenceUuid ? t("settings.fence.validate.success_edit") : t("settings.fence.validate.success_create")}
          </div>
        )}
      </ModalView>
    </div>
  )
}
