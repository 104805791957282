import React, { useEffect, useRef, useState } from "react"
import { Tabs, Tab, Row, Button, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { PARTNER, usePartner } from "@src/partner"
import { putPartnerUuid } from "@src/api"
import { ColorPicker } from "../components"
import { cloudinary } from "@src/config"
import { ManageTrackers } from "./ManageTrackers"
import { AddTrackers } from "./AddTrackers"

const TABS = ["brand", "manage", "add"]
const imgBorderRadius = { borderRadius: 10 }

export const PartnerTabs: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab")
  const { brand, partner, updatePartners, changePartner } = usePartner()
  const [partnerData, setPartnerData] = useState<PARTNER>()
  const [colorLoading, setColorLoading] = useState<boolean>(false)
  const [image, setImage] = useState<any>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const [logoUrl, setLogoUrl] = useState<string>()

  const handleUpload = () => {
    inputRef.current?.click()
  }

  const uploadImages = async (imageData) => {
    if (imageData) {
      setImageLoading(true)
      const data = new FormData()
      data.append("file", imageData)
      data.append("upload_preset", cloudinary.upload_preset)
      data.append("cloud_name", cloudinary.name)
      fetch(`${cloudinary.uploadEndPoint + cloudinary.name}/image/upload`, {
        method: "post",
        body: data,
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.data) {
              const partnerImageData = { ...partnerData, ["logo_url"]: res.data.url }
              setLogoUrl(res.data.url)
              updatePartnerDetails(partnerImageData)
            }
          }),
      )
    }
  }

  const updatePartnerDetails = (partnerData) => {
    putPartnerUuid(partner.uuid, { partner: { ...partnerData } }).then(({ response, data }) => {
      if (response.ok) setPartnerData(partnerData)
      updatePartners()
      setColorLoading(false)
      setImageLoading(false)
      changePartner(partnerData.uuid)
    })
  }

  useEffect(() => {
    if (!TABS.includes(tab)) setSearchParams({ tab: "brand" }, { replace: true })
    if (partnerData != partner) {
      setPartnerData(partner)
      setLogoUrl(partner.logo_url)
    }
  }, [partner])

  return (
    <Tabs activeKey={tab} onSelect={(key) => setSearchParams({ tab: key })} className="mb-3">
      <Tab eventKey={TABS[0]} title={t("settings.partner.tabs.brand")}>
        {partnerData ? (
          <>
            <Row>
              <div className="d-flex">
                <b className="small">{t("settings.partner.brandColor")}</b>
              </div>
              <ColorPicker
                onChangeColor={(color) => {
                  const partnerColorData = { ...partnerData, ["color"]: color.replace("#", "") }
                  setPartnerData(partnerColorData)
                  setColorLoading(true)
                  updatePartnerDetails(partnerColorData)
                }}
                loading={colorLoading}
                color={
                  partnerData && !partnerData.color.includes("#") ? `#${partnerData.color}` : `${partnerData.color}`
                }
              />
            </Row>
            <Row>
              <div className="mt-2 ">
                <b className="small">{t("settings.partner.logo")}</b>
                <Row>
                  <div>
                    {imageLoading ? (
                      <Spinner className="ms-3 mt-3" animation="border" size="sm" />
                    ) : (
                      <img className="mt-2" src={logoUrl} height="70" style={imgBorderRadius} />
                    )}
                    <input
                      ref={inputRef}
                      className="d-none"
                      type="file"
                      onChange={(e) => {
                        setImage(e.target.files[0])
                        uploadImages(e.target.files[0])
                      }}
                    />
                    <Button size="sm" onClick={handleUpload} className="mt-2 ms-3 btn btn-primary-outline">
                      {t("settings.partner.browse")}
                    </Button>
                  </div>
                </Row>
                <Row>
                  <label className="mt-2">{image && image.name}</label>
                </Row>
              </div>
            </Row>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
      </Tab>
      {/* the below code is added for a temporary flow until the backend is ready  */}
      <Tab eventKey={TABS[1]} title={t("settings.partner.alert")}>
        <ManageTrackers />
      </Tab>
      <Tab eventKey={TABS[2]} title={t("settings.partner.add_tracker")}>
        <AddTrackers />
      </Tab>
    </Tabs>
  )
}
